@import "../../styles/responsive.scss";
.header {
  display: flex;
  flex-direction: column;
  font-family: "PopppinsRegular";

  .bg_header {
    background: rgb(113, 113, 113);
    background: -moz-linear-gradient(
      270deg,
      rgba(113, 113, 113, 1) 0%,
      #222222 100%
    );
    background: -webkit-linear-gradient(
      270deg,
      rgba(113, 113, 113, 1) 0%,
      rgba(21, 21, 21, 1) 100%
    );
    background: linear-gradient(
      270deg,
      rgba(113, 113, 113, 1) 0%,
      rgba(21, 21, 21, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#717171",endColorstr="#151515",GradientType=1);

    h1 {
      font-family: OrbitronSemiBold;
      font-size: 20px;
    }
  }

  .top_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #166b9c;

    .ico_logout_sm {
      cursor: pointer;
      @include xxl {
        display: none;
      }
      @include md {
        display: flex;
      }
    }

    .header_title {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
      .app_title {
        font-family: "OrbitronSemiBold";
        font-size: 22px;

        @include md {
          font-size: 18px;
        }
        @include sm {
          display: none;
        }
      }
      .app_title_sm {
        font-family: "OrbitronSemiBold";
        @include xxl {
          display: none;
        }
        @include sm {
          display: flex;
        }
      }
    }

    .icons {
      display: flex;
      gap: 3px;
      color: #ffffffa2;

      .ico {
        font-size: 20px;
        color: #ffffffa2;
      }

      span {
        cursor: pointer;
        border-bottom: 1px solid #ffffffa2;

        &:hover {
          border: none;
          color: #54a4cfa2;
        }
      }

      &.sm_Screen {
        display: flex;

        @include md {
          display: none;
        }
      }
    }
  }
  .bottom_header {
    padding: 10px 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: #166b9c;

    .app_subtitle {
      display: flex;
      gap: 10px;
      align-items: center;
      color: #fff;

      span {
        &:nth-child(1) {
          color: #ffffffa2;
        }
        &:nth-child(2) {
          color: #fff;
          font-weight: bold;
        }
        &:nth-child(3) {
          color: #ffffffa2;
          text-decoration: underline;
          cursor: pointer;
        }
        &:nth-child(4) {
          color: #ffffffa2;
          text-decoration: underline;
          cursor: pointer;
        }

        &.passChange {
          @include sm {
            display: none;
          }
        }
      }
    }
  }
}
