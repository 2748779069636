@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.dialog_attached_File {
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  background-color: #010101d6;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 100;

  .header_t {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: #1c211d;
    width: 100%;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #9695954f;

    .header_title {
      display: flex;
      align-items: center;

      .title_h {
        font-size: 15px;
        font-family: MonserratRegular;
        font-weight: bold;
        color: #e2e2e4;
      }
      .name {
        font-size: 14px;
        font-family: MonserratRegular;
        color: #e2e2e4e1;

        @include sm {
          display: none;
        }
      }
      .ico {
        color: #e2e2e4 !important;
      }
    }

    .ico {
      cursor: pointer;
      color: #e2e2e4 !important;
    }
  }

  .dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    padding: 5px;
    width: 60vw;
    min-height: 60vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @include md {
      width: 80vw;
    }
    @include sm {
      width: 80vw;
      height: 90vw;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 15px 15px;
    padding: 10px;
    border-top: 1px solid #e3dfdf;
    gap: 10px;

    span {
      font-size: 12px;
      padding: 6px 20px;
      border: 1px solid $secondary-black-color;
      cursor: pointer;

      &.validate {
        background-color: $secondary-green-color;
      }
      &.cancele {
        color: #cb4621;
        // background-color: #fde5d0;
      }
    }
  }
}
