.logo {
  width: 50px;
  height: 50px;
  color: #8d6103;
}

.bg_footer {
  background: rgb(113, 113, 113);
  background: -moz-linear-gradient(
    300deg,
    #243e65 0%,
    #031531 30%,
    #030d1d 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #243e65 0%,
    #031531 30%,
    #030d1d 100%
  );
  background: linear-gradient(300deg, #243e65 0%, #031531 30%, #030d1d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#717171",endColorstr="#151515",GradientType=1);
}

.bg_Footer_square {
  background: rgb(237, 164, 8);
  background: -moz-radial-gradient(
    circle,
    rgba(237, 164, 8, 1) 10%,
    #031531 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(237, 164, 8, 1) 10%,
    #031531 100%
  );
  background: radial-gradient(circle, rgba(237, 164, 8, 1) 10%, #031531 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eda408",endColorstr="#464242",GradientType=1);
}
