@import "../../styles/responsive.scss";

.NewActivity {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(16, 23, 37, 0.904);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .dialog {
    width: 60%;
    margin-top: -50px;
    background-color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    @include md {
      width: 85%;
    }

    .top {
      border-bottom: 1px solid #777;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      .ico {
        cursor: pointer;
      }

      .title {
        display: flex;
        align-items: center;
      }
    }
    .bottom {
      border-bottom: 1px solid #777;
      display: flex;
      flex-direction: column;
      padding: 30px 30px;

      .items {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .item {
          display: flex;
          // align-items: flex-start;
          flex-direction: column;
          gap: 2px;

          input,
          textarea {
            width: 100%;
            padding: 3px 5px;
            outline: none;
            border: 1px solid #c1c1c1;
            border-radius: 5px;
          }
          select {
            width: 20%;
            padding: 3px 5px;
            outline: none;
            border: 1px solid #c1c1c1;
            border-radius: 5px;
          }
        }
      }
      .btns {
        display: flex;
        margin-top: 30px;
        gap: 20px;

        button {
          border: none;
          padding: 4px 8px;
          &:nth-child(1) {
            background-color: #590e07;
            border: 1px solid #080c3998;
            color: white;

            &:hover {
              border: 1px solid #080c3998;
              background-color: transparent;
              color: #080c3998;
            }
          }
          &:nth-child(2) {
            background-color: #a91e08b3;
            border: 1px solid #080c3998;
            color: rgb(206, 194, 194);

            &:hover {
              border: 1px solid #080c3998;
              background-color: transparent;
              color: #a91e08b3;
            }
          }
        }
      }
    }
  }
}
