.errorBar {
  width: 100%;
  background-color: #5a070790;
  align-items: center;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #e7e7e7;
  z-index: 99999;

  .sp1 {
    font-family: "MonserratRegular", Arial, sans-serif;
  }
}
