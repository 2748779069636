.page_title {
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 20px;
  }

  .btn {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #565656;
    border-radius: 30px;
    color: #e6e6e6;
    padding-inline: 20px;

    &:hover {
      background-color: #757575;
    }
  }
}
