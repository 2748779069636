@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: PoppinsRegular;

  .left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @include md {
      display: none;
    }

    .logoContainer {
      padding: 100px 100px 100px 100px;
      display: inline-block;
      border-radius: 40px;
      background-color: rgb(76, 156, 100);
      margin-right: -200px;
      background-image: linear-gradient(
        180deg,
        hsl(75deg 88% 25%) 0%,
        hsl(118deg 31% 45%) 9%,
        hsl(156deg 37% 49%) 19%,
        hsl(174deg 42% 58%) 28%,
        hsl(187deg 49% 73%) 38%,
        hsl(199deg 59% 87%) 47%,
        hsl(205deg 61% 94%) 57%,
        hsl(205deg 61% 95%) 66%,
        hsl(205deg 61% 96%) 75%,
        hsl(205deg 61% 97%) 84%,
        hsl(205deg 61% 99%) 93%,
        hsl(0deg 0% 100%) 100%
      );

      @include xl {
        margin-right: -100px;
      }

      @include lg {
        padding: 50px 50px 50px 50px;
        margin-right: -70px;
      }
      @include md {
        margin-right: -30px;
      }

      img {
        width: 240px;
        height: 140px;
        object-fit: contain;

        @include xl {
          width: 200px;
          height: 100px;
        }
      }

      .appName {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        font-family: "OrbitronSemiBold";

        span {
          &:first-child {
            color: $primary-green-color;
            font-weight: 700;
            font-size: 30px;
          }

          &:last-child {
            color: $primary-black-color;
            font-weight: 700;
            font-size: 30px;
          }
        }
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    // background-color: aqua;

    @include sm {
      gap: 40px;
    }

    .topText {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 20%;

      img {
        width: 80px;
        height: 70px;
      }

      span {
        font-size: 12px;
        color: $secondary-black-color;
      }

      h3 {
        padding: 5px 10px;
        font-family: OrbitronSemiBold;
        background: rgb(113, 113, 113);
        background: -moz-linear-gradient(
          270deg,
          rgba(113, 113, 113, 1) 0%,
          rgba(21, 21, 21, 1) 100%
        );
        background: -webkit-linear-gradient(
          270deg,
          rgba(113, 113, 113, 1) 0%,
          rgba(21, 21, 21, 1) 100%
        );
        background: linear-gradient(
          270deg,
          rgba(113, 113, 113, 1) 0%,
          rgba(21, 21, 21, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#717171",endColorstr="#151515",GradientType=1);
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 250px;
      gap: 10px;

      @include sm {
        gap: 15px;
      }

      .item {
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-size: 12px;
          font-weight: 500;
          color: $secondary-black-color;
        }

        input {
          height: 30px;
          background-color: transparent;
          border: 1.4px solid #b6bdbd;
          border-radius: 4px;
          padding-left: 5px;
          font-size: 12px;
          color: #878888;
          &::placeholder {
            font-family: PoppinsRegular;
          }

          &:focus {
            outline: none;
            padding-left: 5px;
            background-color: transparent;
          }

          &::placeholder {
            font-size: 11px;
          }
        }
      }

      .remember {
        display: flex;
        justify-content: space-between;
        margin-left: -5px;

        .left {
          display: flex;

          input {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            cursor: pointer;

            // &:checked {
            //   background-color: #878888;
            //   height: 20px;
            // }

            .checkbox {
              &:checked::before {
                accent-color: red;
                // background-color: $secondary-green-color;
                // height: 10px;
              }
            }
          }

          span {
            font-size: 12px;
          }
        }

        .right {
          font-size: 11px;
          color: rgb(66, 147, 218);
          font-weight: 500;
          cursor: pointer;
          // letter-spacing: -0.5px;
        }
      }

      .connexion {
        width: 250px;
        height: 32px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid #edab1d;
        font-family: "OrbitronSemiBold";
        color: #edab1d;

        &:hover {
          //   background-color: #edab1d;
          border: 1px solid #edab1d;
          color: white;
          background: rgb(113, 113, 113);
          background: -moz-linear-gradient(
            270deg,
            rgba(113, 113, 113, 1) 0%,
            #edab1d 100%
          );
          background: -webkit-linear-gradient(
            270deg,
            rgba(113, 113, 113, 1) 0%,
            #edab1d 100%
          );
          background: linear-gradient(
            270deg,
            rgb(215, 215, 215) 0%,
            #90670f 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#717171",endColorstr="#151515",GradientType=1);
        }
      }
    }
  }

  .errorMsg {
    font-size: 12px;
    color: rgb(223, 9, 9);
  }
}
