@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.page_notFound {
  width: 100vw;
  height: 100vh;
  display: flex;

  @include md {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    @include md {
      margin-top: 100px;
    }

    h2 {
      font-size: 30px;
    }

    h1 {
      font-size: 100px;
    }
  }

  .right {
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      width: 200px;
      height: 200px;
      object-fit: cover;

      @include lg {
        width: 300px;
        height: 150px;
        margin-left: -100px;
      }

      @include md {
        margin-left: 0;
      }
    }
  }
}
