@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.itemss {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .left_title {
    color: #95a5a6;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .left_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #a1a1a1;
    padding: 10px;
    gap: 30px;
    text-align: justify;
    border-bottom: 1px solid #bab9b9;
    cursor: pointer;
    transition: ease 0.5s;

    &:hover {
      padding-left: 20px;
    }
    @include sm {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label {
        font-weight: bolder;
        text-decoration: underline;
      }
    }
    .media {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .update {
      display: flex;
      align-items: center;
    }
  }

  .responsive-tables {
    font-family: "lato", sans-serif;
    font-size: 12px;
    width: 100%;
    margin-left: -15px;

    li {
      border-radius: 3px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .table-header {
      background-color: #95a5a6;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .col-1 {
      flex-basis: 10%;
      display: flex;
      align-items: center;
      gap: 10px;

      .case {
        width: 20px;
        height: 20px;
        border: 1px solid #7777;
        border-radius: 5px;
      }
    }
    .col-2 {
      flex-basis: 15%;
    }
    .col-3 {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-basis: 25%;
      justify-content: space-between;

      .img {
        width: 25px;
        height: 25px;
        border: 1px solid #8888;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.linkBtn {
        display: flex;
        justify-content: space-between;

        h6 {
          text-decoration: underline;
          font-size: 12px;
        }
      }
      .status {
        padding: 2px 5px;
        border-radius: 5px;

        &.enable {
          font-size: 10px;
          color: $primary-green-color;
          background-color: $soft-green-color;
        }

        // &.reserved {
        //   font-size: 10px;
        //   color: $primary-reserved-color;
        //   background-color: $soft-reserved-color;
        // }

        // &.pending {
        //   font-size: 10px;
        //   color: $primary-orange-color;
        //   background-color: $soft-orange-color;
        // }
        &.disable {
          font-size: 10px;
          color: $primary-red-color;
          background-color: $soft-red-color;
        }

        // &.dette {
        //   font-size: 10px;
        //   color: $primary-notify-color;
        //   background-color: $soft-notify-color;
        // }
      }
    }
    .col-4 {
      flex-basis: 25%;
    }

    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
      // .table-row{

      // }
      li {
        display: block;
      }
      .col {
        // flex-basis: 100%;
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6c7a89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }
}
