@import "../../styles/variables.scss";

.home {
  display: flex;
  flex-direction: column;
  font-family: "PopppinsRegular";

  .container {
    display: flex;
    gap: 30px;
    width: 80%;

    .left {
      flex: 5;

      .left_title {
        color: #fff;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .left_item {
        color: #a1a1a1;
        padding: 10px;
        border-bottom: 1px solid #bab9b9;
        cursor: pointer;
        transition: ease 0.5s;

        &:hover {
          padding-left: 20px;
        }
      }
    }
    .right {
      flex: 2;
      background-color: #f9f7f7;
      .recent_title {
        padding: 10px;
        border-bottom: 1px solid #757575;
      }
      .recent_items {
        padding: 10px;

        .recenth1 {
          font-weight: bold;
        }
        .itemContainer {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          gap: 10px;

          .item {
            display: flex;
            gap: 10px;
            align-items: flex-start;

            .ico {
              font-size: 16px;
              color: #f8d216;
            }
            .right_item {
              display: flex;
              flex-direction: column;
              margin-top: -2px;

              span {
                &:nth-child(1) {
                  color: #035674;
                  font-size: 12px;
                }
                &:nth-child(2) {
                  color: #565656;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
