// MAIN TEXT COLORS
// $primary-green-color:#177c2ede;

$primary-color: #004b76;
$primary-soft-color: #2688ac;

$secondary-color: #5bca2b45;

$primary-green-color: #4a8029;
$soft-green-color: #5bca2b45;

$primary-orange-color: #e4ad06;
$soft-orange-color: #d8a8152f;

$secondary-green-color: #00b69b;

$primary-red-color: #ff6666;
$soft-red-color: #c841233d;

$primary-white-color: #ffffff;
$secondary-white-color: #f5f6fa;

$primary-black-color: #000000;
$secondary-black-color: #616365;

$title_bg_color: #e8f0ea;
//MAIN BG COLORS
