@import "../../styles/variables.scss";
.container {
  display: flex;
  gap: 30px;
  width: 80%;

  .left {
    flex: 5;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .search {
      display: flex;
      align-items: center;
      gap: 3px;
      .ico {
        font-size: 30px;
        color: #777;
      }

      input {
        width: 100%;
        border-radius: 10px;
        outline: none;
        border: 1px solid #a3a3a3df;
        padding-inline: 10px;
        height: 30px;
      }
    }
    .action {
      display: flex;
      gap: 10px;
      align-items: center;

      select {
        // width: 60%;
        border-radius: 10px;
        outline: none;
        border: 1px solid #a3a3a3df;
        padding-inline: 10px;
        height: 30px;
      }
      button {
        border: 1px solid #a3a3a3df;
        padding: 2px 12px;
        border-radius: 5px;
      }
    }

    .responsive-table {
      font-family: "lato", sans-serif;
      font-size: 12px;
      width: 100%;
      // background-color: #f8d216;

      li {
        border-radius: 3px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .table-header {
        background-color: #95a5a6;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
      .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .case {
        width: 20px;
        height: 20px;
        border: 1px solid #7777;
        border-radius: 5px;
      }
      // .caseChecked {
      //   width: 20px;
      //   height: 20px;
      //   border: 1px solid #777;
      //   background-color: #218607b6;
      //   border-radius: 5px;
      // }
      .col-1 {
        flex-basis: 10%;
      }
      .col-2 {
        flex-basis: 40%;
      }
      .col-3 {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-basis: 25%;
      }
      .col-4 {
        flex-basis: 25%;
      }

      @media all and (max-width: 767px) {
        .table-header {
          display: none;
        }
        // .table-row{

        // }
        li {
          display: block;
        }
        .col {
          // flex-basis: 100%;
        }
        .col {
          display: flex;
          padding: 10px 0;
          &:before {
            color: #6c7a89;
            padding-right: 10px;
            content: attr(data-label);
            flex-basis: 50%;
            text-align: right;
          }
        }
      }
    }
  }
  .right {
    flex: 2;
    background-color: #f9f7f7;
    .recent_title {
      padding: 10px;
      border-bottom: 1px solid #757575;
    }
    .recent_items {
      padding: 10px;

      .recenth1 {
        font-weight: bold;
      }
      .itemContainer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 10px;

        .item {
          display: flex;
          gap: 10px;
          align-items: flex-start;

          .ico {
            font-size: 16px;
            color: #f8d216;
          }
          .right_item {
            display: flex;
            flex-direction: column;
            margin-top: -2px;

            span {
              &:nth-child(1) {
                color: #035674;
                font-size: 12px;
              }
              &:nth-child(2) {
                color: #565656;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
