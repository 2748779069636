.updatePass {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(16, 23, 37, 0.904);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .dialog {
    width: 50%;
    margin-top: -50px;
    background-color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .top {
      border-bottom: 1px solid #777;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      .ico {
        cursor: pointer;
      }

      .title {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .bottom {
      border-bottom: 1px solid #777;
      display: flex;
      flex-direction: column;
      padding: 30px 30px;
      position: relative;

      .items {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
          display: flex;
          flex-direction: column;
          gap: 2px;
          align-items: center;
          width: 100%;

          input {
            width: 80%;
            padding: 3px 5px;
            outline: none;
            border: 1px solid #c1c1c1;
          }

          .remember {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .left {
              display: flex;
              align-items: center;

              input {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                cursor: pointer;

                // &:checked {
                //   background-color: #878888;
                //   height: 20px;
                // }

                .checkbox {
                  &:checked::before {
                    accent-color: red;
                    // background-color: $secondary-green-color;
                    // height: 10px;
                  }
                }
              }

              span {
                font-size: 14px;
                text-decoration: underline;
              }
            }

            .right {
              font-size: 11px;
              color: rgb(66, 147, 218);
              font-weight: 500;
              cursor: pointer;
              // letter-spacing: -0.5px;
            }
          }
          select {
            outline: none;
            border: 1px solid #c1c1c1;
            width: 15%;
            height: 28px;
          }
        }
      }
      .btns {
        display: flex;
        margin-top: 30px;
        gap: 20px;
        align-items: center;
        justify-content: center;

        button {
          border: none;
          padding: 4px 8px;
          &:nth-child(2) {
            background-color: #060b49;
            border: 1px solid #080c3998;
            color: white;

            &:hover {
              border: 1px solid #080c3998;
              background-color: transparent;
              color: #b44e4ed3;
            }
          }
          &:nth-child(1) {
            background-color: #a91e08b3;
            border: 1px solid #080c3998;
            color: white;

            &:hover {
              border: 1px solid #080c3998;
              background-color: transparent;
              color: #080c3998;
            }
          }
        }
      }

      .editBtn {
        position: absolute;
        top: 30px;
        right: 30px;
        color: skyblue;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        span {
          font-size: 10px;
          text-decoration: underline;
        }
      }
      .addImg {
        position: absolute;
        top: 60px;
        right: 30px;
        color: skyblue;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          font-size: 10px;
          text-decoration: underline;
        }
      }
    }
  }
}
