@import "../../styles/responsive.scss";
.items {
  flex: 5;

  .left_title {
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .left_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #a1a1a1;
    padding: 10px;
    gap: 30px;
    text-align: justify;
    border-bottom: 1px solid #bab9b9;
    cursor: pointer;
    transition: ease 0.5s;

    &:hover {
      padding-left: 20px;
    }

    @include sm {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label {
        font-weight: bolder;
        text-decoration: underline;
      }
    }

    // span {
    //   .content_ {
    //     @include sm {
    //       display: none;
    //     }
    //   }
    // }

    .media {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .content_ {
      @include sm {
        display: none;
      }
    }

    .update {
      display: flex;
      align-items: center;

      .text_disabled {
        color: #aca3a3;
        cursor: not-allowed;
      }
    }
  }
}
